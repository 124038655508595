.MuiAccordion-root {
  background: var(--ion-header-background);
  .MuiIconButton-label {
    svg {
      color: #fff;
      fill: #fff;
    }
  }
  .MuiAccordionSummary-root {
    font-style: normal;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    align-items: center;
    background: var(--theme2-bg);
    // border-top-left-radius: 3px;
    // border-top-right-radius: 3px;
    // border-bottom-left-radius: 3px;
    // border-bottom-right-radius: 3px;
    padding: 12px 20px;
    margin-top: 0px;
    height: 30px !important;
    min-height: 30px !important;
    &.Mui-expanded {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .MuiAccordionDetails-root {
    display: initial;
    padding: 0;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

// Accordians in modals
// Ex: Rules accoridan body
.MuiDialog-paper {
  .MuiAccordion-root {
    .MuiButtonBase-root {
      border-radius: 0;
    }

    .MuiAccordionDetails-root {
      font-size: 14px;
      padding-left: 10px;
      border-radius: 0;
    }
  }
}

@media only screen and (max-width: 720px) {
  .MuiAccordion-root {
    // Accordian header
    .MuiAccordionSummary-root {
      border-radius: 0;
      padding: 0;
      font-size: 14px;
      line-height: 17px;
      height: 25px !important;
      min-height: 25px !important;

      .MuiAccordionSummary-content {
        padding-left: 10px;
      }
    }
    // Accordian boday
    .MuiAccordionDetails-root {
      // padding: 8px 12px;
      padding: 0;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}
.MuiSvgIcon-root,
svg {
  fill: #fff;
}
