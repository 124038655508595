.MuiInputBase-root {
  color: var(--site-color);
  font-family: Inter;
  font-size: 14px;
  height: 40px;
  padding: 0;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 8px;
  border: 2px solid var(--ion-background);

  .MuiFormControl-root {
    background: var(--input-background-color) !important;
  }

  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    padding: 0px;
    font-size: 14px;
    font-family: Inter;
    padding: 14px 16px;
  }
  .MuiSvgIcon-root {
    color: var(--input-text-color);
  }

  .MuiFormHelperText-contained {
    margin: 0px !important;
    background: none !important;
  }

  .Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
  }
}

/* Input Styles */

// .MuiInputBase-root .MuiFormControl-root,
// .MuiOutlinedInput-root,
// input {
//   background: var(--input-background-color) !important;
//   color: var(--input-text-color) !important;
//   border-radius: 8px;
// }

.sc-ion-input-md-h input {
  border-radius: 8px !important;
}

// .sc-ion-input-md-h, input {
//   border-radius: 8px !important;
//   border: 1px solid var(--input-border-color);
//   background-color: var(--input-background-color);
// }

.input-label {
  font-family: Inter;
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 6px;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
  -webkit-transition: background-color 9999s ease-out !important;
}

.modal-content-ctn {
  .date-control,
  .input-control {
    border: 1px solid #ccc;
  }
}

.MuiFormControlLabel-label {
  color: var(--site-color);
}
.form-input {
  display: inline-grid;
  margin-top: 1rem;
  width: 100%;
  color: var(--site-color);
}
