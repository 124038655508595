.login-form-ctn {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color);
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: 4px;
  width: 320px;

  .form-title {
    color: var(--theme1-bg);
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    padding: 9px;
  }

  .input-control {
    margin-bottom: 1.5rem !important;
  }

  .login-and-demo-login {
    display: flex;
    justify-content: space-around;
    .login-form-btn {
      width: 48%;
      // margin-top: 1.3rem;
      background-color: var(--theme1-bg);
      font-weight: 400;
      text-align: center;

      .fa-android {
        margin-left: 10px;
      }

      .ml-5 {
        margin-right: 5px;
        letter-spacing: 0;
      }
    }

    .login-form-btn-without-demologin {
      width: 100%;
      background-color: var(--theme1-bg);
      font-weight: 400;
      text-align: center;

      .fa-android {
        margin-left: 10px;
      }

      .ml-5 {
        margin-right: 5px;
        letter-spacing: 0;
      }
    }
  }

  .sign-up-btn {
    padding-top: 10px;
    .login-form-btn-without-demologin {
      width: 100%;
      background-color: var(--theme1-bg);
      font-weight: 400;
      text-align: center;

      .fa-android {
        margin-left: 10px;
      }

      .ml-5 {
        margin-right: 5px;
        letter-spacing: 0;
      }
    }
  }

  .login-form-btn:hover {
    background-color: var(--theme1-bg);
  }

  .recaptchaTerms {
    font-size: 82%;
    font-weight: bold;
    color: var(--site-color) !important;
    margin: 6px 0;
    font-family: 'Roboto Condensed', sans-serif !important;

    .nav-item {
      text-decoration: none;
      line-height: 1.6;
      font-family: 'Roboto Condensed', sans-serif !important;
    }
  }

  .bottom-link {
    margin-top: auto;
    margin-bottom: 1rem;
    font-size: 14px;
    text-align: center;

    .register-link {
      color: var(--ion-color-primary);
      cursor: pointer;
    }
  }

  .usr-input,
  .login-input-field {
    height: 35px;
    width: 100%;

    // margin: auto 4px;
    input {
      height: 100%;
      width: 100%;
      font-size: 1rem !important;
      padding: 5px !important;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: bold;
    }

    input::placeholder {
      color: #6c757d;
      font-size: 14px;
      padding: 5px !important;
      opacity: 1;
      font-weight: bold;
      font-family: 'Roboto Condensed', sans-serif;
    }
  }

  .pwd-input {
    // margin-top: 1rem;
    .pwd-field {
      height: 35px;
      width: 100%;

      //   margin: auto 4px;
      input {
        height: 100%;
        width: 100%;
        font-size: 12px !important;
        padding: 5px !important;
      }

      input::placeholder {
        color: #6c757d;
        font-size: 14px;
        padding: 5px !important;
        opacity: 1;
      }

      .input-icon {
        background: #fff;
      }
    }
  }

  .icon-title {
    margin-top: 3rem;
    padding-left: 0%;

    ion-img {
      width: var(--logo-xl-width);
      height: var(--logo-xl-height);
    }
  }
}

@media screen and (max-width: 721px) {
  .login-form-ctn {
    .input-control {
      .MuiInputBase-root {
        border-radius: 0 !important;

        fieldset {
          border: 0;
          border-bottom: 1px solid var(--theme2-bg);
        }
      }
    }
  }
}
