.events-table-ctn {
  .banner-container {
    // background: url('../../assets/images/sportsbook/banners/cricket-kohli.png');
    background-size: cover;
    height: 225px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0px;
    overflow: hidden;
    font-family: Bebas Neue;
    cursor: default;
    .sport-image {
      height: 140px;
      margin-left: 5%;
      &.inplay-image {
        height: 80px;
      }
    }

    .banner-nav-link {
      color: #fff;
      text-decoration: none;
    }

    .eventType-name {
      font-family: Bebas Neue;
      font-style: normal;
      font-weight: normal;
      font-size: 82px;
      line-height: 98px;
      letter-spacing: 0.1em;
      color: #f8f8f8;
      margin-left: 12%;
      margin-right: 22%;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      align-items: center;
      .inplay-live-icon {
        height: 58px;
        width: 58px;
        margin-right: 21px;
        margin-top: -8px;
      }
    }
  }

  .MuiPaper-root {
    background-color: var(--ion-background);
  }

  .eventType-accordion {
    box-shadow: none;
    margin-top: 8px !important;
    .eventType-header {
      font-family: 'Mulish', sans-serif !important;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
      color: var(--accordian-headers-color) !important;
      padding: 6px 16.2px 9px 22px;
      height: 26px !important;
      display: flex;
      align-items: center;
      overflow: hidden;
      font-weight: 600;

      .sport-inplay-icon {
        height: 24px;
        width: 24px;
        margin-right: 12px;
      }
      .MuiAccordionSummary-expandIcon {
        opacity: 1;
        .expand-icon {
          color: var(--accordian-headers-color) !important;
        }
      }
    }
    .inplay-events-tbl-container,
    .events-table-content {
      padding: 0px;
      background: #fff;
      .all-markets-nav-link {
        cursor: pointer !important;
        .team-name {
          color: var(--site-color) !important;
          display: flex;
          justify-content: space-between;
          width: 95%;
          vertical-align: middle;
          font-family: 'Roboto Condensed', sans-serif !important;
          text-transform: capitalize;
          cursor: pointer !important;
          .span {
            color: var(--odds-font-color) !important;
          }
          .team {
            color: var(--odds-font-color) !important;
            font-size: 14px;
            font-weight: bold; text-transform: capitalize;

          }
          .fa-circle {
            font-size: 9px;
            color: #008641;
            margin-top: 4px;
            margin-right: 20px;
          }
          .bm {
            height: 12px;
            width: 17px;
            margin-top: 4px;
          }
        }

        .odds-web-view {
          .teams .team-name {
            font-weight: bolder;
            width: 95%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--odds-font-color) !important;
            font-family: 'Roboto Condensed', sans-serif !important;
            font-size: 13px;
          }
        }
      }
    }
  }

  .table-ctn,
  .tbl-ctn {
    .MuiPaper-root {
      margin-top: 0;
      border-radius: 0;
    }

    .MuiTableContainer-root {
      background: transparent;
      .MuiTable-root {
        .MuiTableHead-root {
          border: none;
          .MuiTableRow-head {
            .MuiTableCell-head {
              white-space: nowrap;
              font-family: 'Roboto Condensed', sans-serif !important;
              font-weight: 600;
              font-size: 13px;
            }

            .MuiTableCell-root {
              padding: 0;
            }
          }
        }

        .MuiTableBody-root {
          border: none;
          .MuiTableRow-root {
            border-top: 1px solid #ccc;

            .all-markets-nav-link {
              font-family: 'Roboto Condensed', sans-serif !important;
              cursor: pointer !important;
            }

            .schedule-cell {
              max-width: 84px;
              white-space: break-spaces;
              font-family: 'Roboto Condensed', sans-serif !important;
            }
          }

          // .MuiTableRow-root:hover {
          //   .team-name {
          //     border-bottom: 1px solid var(--odds-font-color);
          //   }
          // }

          .MuiTableCell-root {
            padding: 0px;
            font-size: 12px;

            .odds-block {
              display: flex;
              flex: 1 1 33%;
              flex-direction: row;
              align-items: center;
              justify-content: space-around;
              margin: 0;
              padding: 0;
              font-size: 12px;
              .back-odd,
              .lay-odd {
                cursor: default !important;
                min-height: 36px;
                padding: 2px 4px;
                font-family: Inter;
                font-style: normal;
                .price {
                  font-weight: 600;
                  font-size: 14px;
                }
                .size {
                  font-size: 10px;
                  font-weight: 500;
                  line-height: 15px;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }

    .live-img {
      width: 34px;
      height: 18px;
    }

    .all-markets-nav-link {
      text-decoration: none;
      cursor: pointer !important;
    }
  }
}

.back-odd {
  background-color: var(--back-odd-background);
}
.lay-odd {
  background-color: var(--lay-odd-background);
}

@media (min-width: 1200px) {
  .events-table-ctn {
    display: flex;
    .events-section {
      width: 75%;
    }
    .sport-info-section {
      width: 24%;
      margin-left: auto;
      background-color: var(--table-body-background);
    }
  }
  .inplay-events-table-ctn {
    flex-direction: column;
    .MuiTableCell-root {
      white-space: nowrap;
      font-family: 'Roboto Condensed', sans-serif !important;
      font-weight: 600;
      font-size: 13px;
      margin-left: 2%;
      border-top: 1px solid #ccc;
    }
    .events-table-msg-text {
      color: var(--site-color);
      background-color: #ccc;
      padding: 5px 15px;
    }
  }
}

@media (max-width: 1200px) {
  .events-table-ctn
    .events-table-content
    .MuiPaper-root
    .events-table
    .MuiTableRow-root
    .odds-cell {
    display: none;
    min-width: 120px;
  }

  .events-table-ctn
    .events-table-content
    .MuiPaper-root
    .events-table
    .MuiTableRow-root
    .odds-cell-head,
  .events-table-ctn
    .events-table-content
    .MuiPaper-root
    .events-table
    .MuiTableRow-root {
    .schedule-cell {
      display: none;
    }
    .MuiTableCell-root {
      .odds-block {
        .back-odd,
        .lay-odd {
          min-height: 26px;
        }
      }
    }
  }

  // .odds-row {
  //   display: flex;
  //   width: 100%;
  //   align-items: center;
  //   margin-top: 6px;
  //   .odds-block {
  //     display: flex;
  //     flex: 1 1 33%;
  //     flex-direction: row;
  //     align-items: center;
  //     justify-content: space-around;
  //     margin: 0px;
  //     padding: 0px;
  //     font-size: 12px;
  //   }
  // }
}

@media (max-width: 1200px) {
  .events-table-ctn
    .events-table-content
    .MuiPaper-root
    .events-table
    .MuiTableRow-root
    .odds-cell {
    display: none;
    min-width: 120px;
  }

  .events-table-ctn
    .events-table-content
    .MuiPaper-root
    .events-table
    .MuiTableRow-root
    .odds-cell-head,
  .events-table-ctn
    .events-table-content
    .MuiPaper-root
    .events-table
    .MuiTableRow-root
    .schedule-cell {
    display: none;
    padding: 0px;
  }

  .inplay-events-table-ctn {
    .sport-btn {
      background-color: var(--theme2-bg);
      color: var(--primary-color);
      font-size: 16px;
      margin-top: 10px;
      min-width: 50px;
      max-width: 68px;
      text-align: center;
      padding: 5px;
    }
    .events-table-msg-text {
      color: var(--site-color);
      background-color: #ccc;
      padding: 4px 15px;
    }
  }

  .odds-row {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 6px;
    .odds-block {
      display: flex;
      flex: 1 1 33%;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin: 0px;
      padding: 0px;
      font-size: 12px;
    }
  }

  .events-table-ctn {
    .banner-container {
      display: none;
    }
    .eventType-accordion {
      .eventType-header {
        font-size: 16px;
        line-height: 18px;
        padding: 7px 14px 7px 7px;
        height: 36px !important;
        border-radius: 0px !important;
        .sport-inplay-icon {
          height: 17px;
          width: 17px;
          margin-right: 8px;
        }
      }
      .sport-inplay-icon {
        height: 24px;
        width: 24px;
        margin-right: 12px;
      }
    }

    .events-table-content {
      .MuiPaper-root {
        margin-top: 0px;
        .events-table {
          // margin-top: 10px;
          border-radius: 0px;
          overflow: hidden;
          .MuiTableRow-root {
            .schedule-cell {
              display: none;
            }
            .odds-cell-head {
              display: none;
            }
            .teams-cell {
              font-family: 'Roboto Condensed', sans-serif !important;
              font-weight: bold;
              .web-view {
                display: none;
              }

              .live-img {
                width: 40px;
                margin-left: 10px;
              }

              min-width: 100% !important;
              max-width: 100% !important;
              .all-markets-nav-link {
                text-decoration: none;
                .team-name {
                  white-space: nowrap;
                  font-size: 13px;
                  color: var(--site-color);
                  font-family: 'Roboto Condensed', sans-serif !important;
                  font-weight: bold;
                  text-transform: capitalize;
                }
                .event-name {
                  display: flex;
                  justify-content: space-between;
                  font-family: 'Roboto Condensed', sans-serif;
                  text-decoration: none;
                  color: var(--site-color);
                  font-weight: bolder;
                  text-transform: capitalize;
                }
                .schedule-time {
                  font-family: 'Roboto Condensed', sans-serif;
                  color: var(--site-color);
                  font-weight: bolder;
                  // font-weight: 500;
                }

                .odds-mob-view {
                  // display: flex;
                  width: 100%;
                  align-items: center;
                  margin-top: 6px;

                  .mob-odds-block {
                    display: flex;
                    flex: 1 1 33%;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;
                    padding: 0;
                    margin: 0;

                    .mob-exch-odd-view {
                      width: 100%;
                      text-align: center;

                      .mob-exch-odd-button:not(.disabled) {
                        // border-radius: 4px;

                        .mob-exch-odd-button-content {
                          // padding: 2px 1.75px;
                          min-height: 26.5px;
                          .price {
                            font-size: 13px !important;
                          }
                        }
                      }

                      .mob-exch-odd-button {
                        .lock-icon {
                          min-width: 42px;
                          margin-top: 4px;
                        }
                      }
                    }
                  }
                }
              }
            }
            .score-cell {
              display: none;
              max-width: 40px !important;
              .score {
                max-width: 100%;
                font-size: 10px;
                line-height: 14px;
              }
            }
            .odds-cell {
              display: none;
              min-width: 120px;
              .odds-block {
                .back-odd,
                .lay-odd {
                  min-width: 40px;
                  max-width: 50px;
                  width: 48%;
                  min-height: 36px;
                  padding: 2px 2px;
                  .price {
                    font-size: 12px;
                    line-height: 15px;
                  }
                  .size {
                    font-size: 10px;
                    line-height: 12px;
                  }
                  &.disabled {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .lock-icon {
                      height: 16px;
                      width: 18px;
                    }
                  }
                }
              }
            }
            .all-markets-link-cell {
              display: none;
              // min-width: 20px;
              // ion-img {
              //   height: 12px;
              //   width: 7px;
              //   margin-right: 0px;
              //   margin-left: auto;
              // }
            }
          }
          .MuiTableRow-root {
            border: 0px;
          }
          .MuiTableHead-root .MuiTableRow-root {
            .MuiTableCell-root {
              padding: 0px;
              font-size: 14px;
              line-height: 15px;
            }
          }
          .MuiTableBody-root .MuiTableRow-root {
            .MuiTableCell-root {
              padding: 0px;
            }
          }

          .mob-odds-view {
            display: flex;
            width: 100%;
            align-items: center;
            margin-top: 6px;
            .mob-odds-block {
              display: flex;
              flex: 1 1 33%;
              flex-direction: row;
              align-items: center;
              justify-content: space-around;
              margin: 0px;
              padding: 0px;
              font-size: 12px;
              .back-odd {
                background-color: var(--back-odd-background);
                font-size: 13px;
              }
              .lay-odd {
                background-color: var(--lay-odd-background);
                font-size: 13px;
              }
            }
          }
        }
      }
    }

    .bs-accordion {
      display: none;
    }
    .footer-row {
      margin-top: 10px;
      .pagination-btn {
        font-size: 11px;
        height: 24px;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .events-table {
    .odds-row {
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: 6px;

      .fa-tv {
        display: none;
      }
    }
  }

  .events-table-ctn {
    .eventType-accordion {
      margin-top: 0 !important;
    }
  }

  // .inplay-events-table-section {
  //   padding: 0;
  // }
}
